import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'

export const useApiSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {
  }

  const blankSetting = {
    key: null,
    label: null,
    value: null,
  }

  return {
    ui,
    blankSetting,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const useApiList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'content', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const blankMailData = {
    id: null,
    name: null,
    content: null,
    config: [],
    created_at: null,
    updated_at: null,
  }

  const setAPIMailDelete = (...arg) => store.dispatch('admin-api-mail/setAPIMailDelete', ...arg)
  const setAPIMailCreate = (...arg) => store.dispatch('admin-api-mail/setAPIMailCreate', ...arg)
  const setAPIMailUpdate = (...arg) => store.dispatch('admin-api-mail/setAPIMailUpdate', ...arg)

  const getAPIMailListData = (ctx, callback) => {
    // 整理filters

    store.dispatch('admin-api-mail/getAPIMailList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankMailData, item),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    blankMailData,
    refetchData,
    refetchTable,

    setAPIMailDelete,
    setAPIMailCreate,
    setAPIMailUpdate,
    getAPIMailListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}
