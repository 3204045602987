<template>
  <div
    v-if="timeSetting"
    :style="setting"
  >
    <div
      v-if="timeSetting.type === 'timeline-s'"
      class="timeline-s"
    >
      <div class="wrapper">
        <div class="center-line" />
        <div
          v-for="(item, index) in timeArray"
          :key="index"
        >
          <div
            class="row row-1"
            :class="(index % 2 === 0) ? 'row-1': 'row-2'"
          >
            <section
              class="section"
              :class="background"
            >
              <b-img
                v-if="line === 'icon'"
                :src="resolveAction(item).icon"
                class="icon"
              />
              <span
                v-if="line === 'dot'"
                class="dot"
              />
              <h3 class="titleTime text-nowrap">
                {{ moment(item.created_at).format("YYYY-MM-DD HH:mm") }}
              </h3>
              <div class="details">
                <span class="title ml-50">{{ resolveAction(item).title }}</span>
              </div>
              <p class="ml-50 content-text">
                <table class="mb-50">
                  <tr>
                    <td>使用IP&nbsp;&nbsp;&nbsp;</td>
                    <td>{{ item.ip }}</td>
                  </tr>

                  <tr v-if="item.identity === 'admin'">
                    <td>管理者&nbsp;&nbsp;&nbsp;</td>
                    <td>
                      <div class="d-block text-nowrap selection-group d-flex align-items-center">
                        <b-link
                          class="font-weight-bold d-block text-nowrap show-text"
                          @click="linkCreatorInfo(item)"
                        >
                          {{ item.user_info.name }}
                        </b-link>
                        <span
                          class="selection-btn-icon-show"
                          @click="linkCreatorInfo(item)"
                        >
                          <b-img
                            src="/dashboard/admin/images/table/external-link.svg"
                            height="14"
                            width="14"
                            class="cursor-pointer mb-25"
                          />
                        </span>
                      </div>
                    </td>
                  </tr>

                  <tr v-if="item.identity === 'client'">
                    <td>使用者&nbsp;&nbsp;&nbsp;</td>
                    <td>
                      <div class="d-block text-nowrap selection-group d-flex align-items-center">
                        <b-link
                          class="font-weight-bold d-block text-nowrap show-text"
                          @click="linkCreatorInfo(item)"
                        >
                          {{ item.user_info.name }} {{ item.user_info.family_name }}
                        </b-link>
                        <span
                          class="selection-btn-icon-show"
                          @click="linkCreatorInfo(item)"
                        >
                          <b-img
                            src="/dashboard/admin/images/table/external-link.svg"
                            height="14"
                            width="14"
                            class="cursor-pointer mb-25"
                          />
                        </span>
                      </div>
                    </td>
                  </tr>

                  <tr v-if="item.identity === 'payment'">
                    <td>操作者&nbsp;&nbsp;&nbsp;</td>
                    <td>
                      <div>
                        金流觸發
                      </div>
                    </td>
                  </tr>

                  <tr v-if="item.content">
                    <td>備註&nbsp;&nbsp;&nbsp;</td>
                    <td>{{ item.content }}</td>
                  </tr>

                </table>

                <b-link
                  v-if="['update', 'pay'].includes(item.action)"
                  v-b-toggle="`collapse-${index}`"
                >
                  顯示更多
                </b-link>

                <b-collapse
                  :id="`collapse-${index}`"
                  class="mt-50"
                >
                  <!-- <div v-if="item.action === 'complete'">
                    {{ item.data.state }}
                  </div> -->

                  <div v-if="item.action === 'update'">
                    <table class="mb-50">
                      <tr>
                        <td>備註 &nbsp;&nbsp;&nbsp;</td>
                        <td>{{ item.data.content }}</td>
                      </tr>
                    </table>
                  </div>

                  <!-- <div v-if="item.action === 'pay' && item.identity === 'client'">
                    <table class="mb-50">
                      <tr>
                        <td>串接金流 &nbsp;&nbsp;&nbsp;</td>
                        <td>{{ item.data.payment.name }}</td>
                      </tr>
                    </table>
                  </div> -->

                  <!-- {{ item.data }} -->
                </b-collapse>

              </p>
            </section>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="timeSetting.type === 'timeline-m'"
      class="timeline-m"
    >
      <div class="wrapper">
        <div class="center-line" />
        <div
          v-for="(item, index) in timeArray"
          :key="index"
        >
          <div
            class="row row-1"
            :class="(index % 2 === 0) ? 'row-1': 'row-2'"
          >
            <section
              class="section"
              :class="background"
            >
              <b-img
                v-if="line === 'icon'"
                :src="item.image"
                class="icon"
              />
              <span
                v-if="line === 'dot'"
                class="dot"
              />
              <div class="set-right">
                <h3 class="titleTime text-nowrap">
                  {{ moment(item.created_at).format("YYYY-MM-DD HH:mm") }}
                </h3>
              </div>
              <div class="details set-right">
                <span class="title ml-50">{{ item.title }}</span>
                <!-- <span v-if="background ==='bg'">{{ moment(item.created_at).format("YYYY-MM-DD HH:mm") }}</span> -->
              </div>
              <p class="ml-50 set-right content-text">
                {{ item.content }}
                <table>
                  <tr>
                    <td>123</td>
                    <td>456</td>
                  </tr>
                </table>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BImg, BCollapse, BLink, VBToggle,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BImg,
    BCollapse,
    BLink,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    timeSetting: {
      type: Object,
      required: true,
    },
    timeArray: {
      type: Array,
      required: true,
    },
  },
  methods: {
    moment,
    linkCreatorInfo(item) {
      if (item.identity === 'admin') {
        this.$router.push({ name: 'admin-user-view', params: { id: item.user_info.id } })
      }
      if (item.identity === 'client') {
        this.$router.push({ name: 'admin-customer-view', params: { id: item.user_info.id } })
      }
    },

    resolveAction(item) {
      const { action } = item
      if (action === 'create') {
        return {
          title: '訂單建立',
          icon: '/dashboard/admin/images/history/order/create.svg',
        }
      }
      if (action === 'update') {
        return {
          title: '訂單更新',
          icon: '/dashboard/admin/images/history/order/update.svg',
        }
      }
      if (action === 'complete') {
        if (item.data.state === 2) {
          return {
            title: '訂單退款',
            icon: '/dashboard/admin/images/history/order/update.svg',
          }
        }
        if (item.data.state === 3) {
          return {
            title: '訂單取消',
            icon: '/dashboard/admin/images/history/order/update.svg',
          }
        }
        if (item.data.state === 5) {
          return {
            title: '訂單完成',
            icon: '/dashboard/admin/images/history/order/compltet.svg',
          }
        }
      }
      if (action === 'pay') {
        if (item.identity === 'admin') {
          return {
            title: '手動付款',
            icon: '/dashboard/admin/images/history/order/pay-2.svg',
          }
        }
        if (item.identity !== 'admin') {
          return {
            title: '金流付款',
            icon: '/dashboard/admin/images/history/order/pay.svg',
          }
        }
      }
      if (action === 'pay_info') {
        return {
          title: '金流詳情',
          icon: '/dashboard/admin/images/history/order/pay_info.svg',
        }
      }
      if (action === 'pay_return') {
        return {
          title: '金流回傳',
          icon: '/dashboard/admin/images/history/order/pay_return.svg',
        }
      }
      return {
        title: '系統錯誤',
        icon: '/dashboard/admin/images/history/order/error.svg',
      }
    },
  },
  setup(props) {
    const setting = ref({
      '--background-color': '#BBBBBB50',
      '--primary-color': '#1685b8',
      '--primary-txt-color': '#34ace0',
      '--line-color': '#094a68',
      '--txt-color': '#ffffff',
    })
    const background = ref('bg')
    const line = ref('dot')

    const {
      bgColor, primaryColor, primaryTxtColor, lineColor, txtColor,
    } = props.timeSetting.color

    setting.value = {
      '--background-color': bgColor,
      '--primary-color': primaryColor,
      '--primary-txt-color': primaryTxtColor,
      '--line-color': lineColor,
      '--txt-color': txtColor,
    }
    background.value = props.timeSetting.background

    line.value = props.timeSetting.line

    return {
      setting,
      background,
      line,
    }
  },
}
</script>
<style lang="scss" scoped>
$bg-color: var(--background-color);
$primary-color: var(--primary-color);
$primary-txt-color: var(--primary-txt-color);
$line-color: var(--line-color);
$txt-color: var(--txt-color);

.timeline-s {
  .wrapper{
    padding: 0 20px;
    position: relative;
    .center-line{
      position: absolute;
      height: 100%;
      width: 4px;
      background: $line-color;
      left: 50%;
      left: 40px;
      transform: translateX(-50%);
    }
    .row {
      display: flex;
       margin: 30px 0 3px 60px;
      .section {
        border-radius: 5px;
        width: 100%;
        position: relative;
        .titleTime {
          display:inline-block;
          background-color: $primary-color;
          color: white;
          border-radius:25px;
          padding:5px 8px;
          font-size:15px;
          text-align:center;
        }
        .details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title {
            display:block;
            color:$primary-txt-color;
            font-size:17px;
            font-weight: 600;
          }
        }
        .icon {
          position: absolute;
          background: #f2f2f2;
          height: 40px;
          width: 40px;
          text-align: center;
          line-height: 40px;
          border-radius: 50%;
          color: $primary-txt-color;
          font-size: 17px;
          left: -60px;
          box-shadow: 0 0 0 4px $line-color, inset 0 2px 0 rgba(0,0,0,0.08), 0 3px 0 4px rgba(0,0,0,0.05);
        }
        .dot {
          position: absolute;
          background: $primary-txt-color;
          height: 20px;
          width: 20px;
          text-align: center;
          line-height: 40px;
          border-radius: 50%;
          color: $primary-txt-color;
          font-size: 17px;
          left: -50px;
          box-shadow: 0 0 0 3px $line-color, inset 0 2px 0 rgba(0,0,0,0.08), 0 3px 0 4px rgba(0,0,0,0.05);
        }
        p {
          margin: 5px 0 17px 0;
        }
      }
      .bg {
        background: $bg-color;
        color: $txt-color;
        padding: 20px;
      }
      .block {
        padding: 20px 0;
      }
      .section::before{
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        top: 28px;
        left: -7px;
        transform: rotate(45deg);
      }
      .bg::before{
        background: $bg-color;
      }
    }
    .row-1{
      justify-content: flex-start;
      section {
        .icon{
          top: 15px;
          right: -60px;
        }
      }
      section::before{
        right: -7px;
      }
    }
    .row-2{
      justify-content: flex-end;
      section {
        .icon{
          top: 15px;
          left: -60px;
        }
      }
      section::before{
        left: -7px;
      }
    }
  }
}

.timeline-m {
  .wrapper{
    padding: 0 20px;
    position: relative;
    .center-line{
      position: absolute;
      height: 100%;
      width: 4px;
      background: $line-color;
      left: 50%;
      transform: translateX(-50%);
    }
    .row {
      display: flex;
      .section{
        border-radius: 5px;
        width: calc(50% - 40px);
        position: relative;
        .titleTime {
          display:inline-block;
          background-color:$primary-color;
          color: white;
          border-radius:25px;
          padding:5px 8px;
          font-size:15px;
          text-align:center;
        }
        .details {
          .title {
            display:block;
            color:$primary-txt-color;
            font-size:22px;
            font-weight: 600;
          }
        }
        .icon {
          position: absolute;
          background-color: #ffffff;
          height: 40px;
          width: 40px;
          text-align: center;
          line-height: 40px;
          border-radius: 50%;
          color: $primary-txt-color;
          font-size: 17px;
          box-shadow: 0 0 0 4px $line-color, inset 0 2px 0 rgba(0,0,0,0.08), 0 3px 0 4px rgba(0,0,0,0.05);
        }
        .dot {
          position: absolute;
          background: $primary-txt-color;
          height: 20px;
          width: 20px;
          text-align: center;
          line-height: 40px;
          border-radius: 50%;
          color: $primary-txt-color;
          font-size: 17px;
          box-shadow: 0 0 0 3px $line-color, inset 0 2px 0 rgba(0,0,0,0.08), 0 3px 0 4px rgba(0,0,0,0.05);
        }
        p {
          margin: 10px 0 17px 0;
        }
      }
      .bg {
        background: $bg-color;
        color: $txt-color;
        padding: 20px;
      }
      .block {
        padding: 20px 0;
      }
      .section::before{
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        top: 28px;
        transform: rotate(45deg);
      }
      .bg::before{
        background: $bg-color;
      }
    }
    .row-1{
      justify-content: flex-start;
      .section {
        .icon{
          top: 15px;
          right: -60px;
        }
        .dot {
          top: 15px;
          right: -50px;
        }
      }
      .block {
        .set-right {
          text-align: right;
        }
      }
      .section::before{
        right: -7px;
      }
    }
    .row-2{
      justify-content: flex-end;
      .section {
        .icon{
          top: 15px;
          left: -60px;
        }
        .dot {
          top: 15px;
          left: -50px;
        }
      }
      .block {
        .set-right {
          text-align: left;
        }
      }
      .section::before{
        left: -7px;
      }
    }
  }
  @media(max-width: 790px){
    .wrapper {
      .center-line {
        left: 40px;
      }
      .row {
        margin: 30px 0 3px 60px;
        .section {
          width: 100%;
          .details {
            .title {
              font-size:17px;
            }
          }
        }
        .section::before {
          left: -7px;
        }
        .icon{
          left: -60px;
        }
        .dot {
          left: -50px;
        }
      }
      .row-1{
        .block {
          .set-right {
            text-align: left;
          }
        }
      }
    }
  }
}

.content-text {
  white-space: pre-line;
}

.selection-group {
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
  &:hover {
  .selection-btn-icon-show {
      opacity: 1;
    }
  }
}
</style>
