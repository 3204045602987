<template>
  <div>
    <!-- 頁面: 訂單詳情(權限: 完成, 註解: 刪除, 優化: 無) -->
    <section
      class="invoice-add-wrapper"
    >
      <b-row class="invoice-preview">
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <b-overlay
            :show="adminOrderState.isBusyLoading"
            variant="transparent"
            opacity="0.5"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- 公司資料及截止日期 -->
              <b-card-body class="invoice-padding pb-0">
                <b-row class="invoice-spacing">
                  <b-col
                    cols="12"
                    xl="6"
                    class="p-0 mb-1"
                  >
                    <div class="invoice-logo">
                      <b-img
                        :src="adminOrderState.orderInfo && adminOrderState.orderInfo.branch_info.id ? adminOrderState.orderInfo.branch_info.icon : '/dashboard/admin/images/logo/logo-text01.png'"
                        alt="logo"
                        height="40"
                      />
                    </div>
                  </b-col>

                  <b-col
                    xl="6"
                    cols="12"
                    class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                  >
                    <div
                      v-if="!adminOrderState.isBusyLoading"
                      class="mt-2"
                    >
                      <div class="invoice-date-wrapper detail-title">
                        <p class="invoice-date-title">
                          訂單編號：
                        </p>
                        <p class="invoice-date">
                          {{ adminOrderState.orderInfo.ssid }}
                        </p>
                      </div>
                      <div class="invoice-date-wrapper invoice-state">
                        <p class="invoice-date-title">
                          訂單狀態：
                        </p>
                        <p class="invoice-date">
                          <b-badge
                            v-if="isOrderManualFree()"
                            variant="primary"
                          >
                            待確認
                          </b-badge>

                          <b-badge
                            v-else
                            :variant="ui.stateType[2][ adminOrderState.orderInfo.state]"
                          >
                            {{ ui.stateType[0][ adminOrderState.orderInfo.state] }}
                          </b-badge>
                        </p>
                      </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          開立日期：
                        </p>
                        <p class="invoice-date">
                          {{ adminOrderState.orderInfo.created_at ? moment(adminOrderState.orderInfo.created_at).format('YYYY/MM/DD HH:mm') : '---' }}
                        </p>
                      </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          截止繳費：
                        </p>
                        <p class="invoice-date">
                          {{ adminOrderState.orderInfo.expire_time ? moment(adminOrderState.orderInfo.expire_time).format('YYYY/MM/DD HH:mm') : '---' }}
                        </p>
                      </div>

                      <div
                        v-if="adminOrderState.orderInfo.creator_info.identity === 'admin'"
                        class="invoice-date-wrapper invoice-state"
                      >
                        <p class="invoice-date-title">
                          訂單建立：
                        </p>
                        <p class="invoice-date">
                          <b-link @click="linkCreatorInfo('admin', adminOrderState.orderInfo.creator_info.id)">
                            {{ adminOrderState.orderInfo.creator ? adminOrderState.orderInfo.creator_info.name : `${adminOrderState.orderInfo.customer_info.name} ${adminOrderState.orderInfo.customer_info.family_name}` }}
                          </b-link>
                        </p>
                      </div>
                    </div>

                    <div
                      v-else
                      class="mt-2"
                    >
                      <div class="invoice-date-wrapper detail-title">
                        <p class="invoice-date-title">
                          訂單編號：
                        </p>
                        <p class="invoice-date">
                          ---
                        </p>
                      </div>

                      <div class="invoice-date-wrapper invoice-state">
                        <p class="invoice-date-title">
                          訂單狀態：
                        </p>
                        <p class="invoice-date">
                          ---
                        </p>
                      </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          開立日期：
                        </p>
                        <p class="invoice-date">
                          ---
                        </p>
                      </div>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          截止繳費：
                        </p>
                        <p class="invoice-date">
                          ---
                        </p>
                      </div>
                      <div class="invoice-date-wrapper invoice-state">
                        <p class="invoice-date-title">
                          訂單建立：
                        </p>
                        <p class="invoice-date">
                          ---
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <hr class="invoice-spacing">

              <!-- 買方資料及付款詳情 -->
              <b-card-body class="invoice-padding pt-0">
                <b-row class="invoice-spacing">
                  <b-col
                    cols="12"
                    xl="6"
                    class="p-0 mb-1"
                  >
                    <h5 class="mb-1">
                      買受人：
                    </h5>

                    <div v-if="!adminOrderState.isBusyLoading">

                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          姓名：
                        </p>

                        <div class="d-block text-nowrap selection-group d-flex align-items-center">
                          <b-link
                            class="font-weight-bold d-block text-nowrap show-text"
                            @click="linkCreatorInfo('client', adminOrderState.orderInfo.customer_info.id)"
                          >
                            <p class="invoice-date">
                              {{ adminOrderState.orderInfo.customer_info.name }}{{ adminOrderState.orderInfo.customer_info.family_name }}
                            </p>
                          </b-link>
                          <span
                            v-if="checkAuthAbility('customer', 'API.Admin.Customer.Id')"
                            class="selection-btn-icon-show"
                            @click="linkCreatorInfo('client', adminOrderState.orderInfo.customer_info.id)"
                          >
                            <b-img
                              src="/dashboard/admin/images/table/external-link.svg"
                              height="14"
                              width="14"
                              class="cursor-pointer mb-25"
                            />
                          </span>
                        </div>
                        <!-- </p> -->
                      </div>

                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          電子郵件：
                        </p>
                        <p class="invoice-date">
                          {{ adminOrderState.orderInfo.customer_info.id ? adminOrderState.orderInfo.customer_info.account : '---' }}
                        </p>
                      </div>
                    </div>

                    <div v-else>
                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          姓名：
                        </p>
                        <p class="invoice-date">
                          ---
                        </p>
                      </div>

                      <div class="invoice-date-wrapper">
                        <p class="invoice-date-title">
                          電子郵件：
                        </p>
                        <p class="invoice-date">
                          ---
                        </p>
                      </div>
                    </div>
                  </b-col>

                  <!-- Col： 付款詳情 -->
                  <b-col
                    xl="6"
                    cols="12"
                    class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                  >
                    <div>
                      <h5 class="mb-1 detail-title">
                        付款詳情：
                      </h5>

                      <div v-if="!adminOrderState.isBusyLoading">
                        <div class="invoice-date-wrapper">
                          <p class="invoice-date-title">
                            付款金額：
                          </p>
                          <p class="invoice-date">
                            ${{ parseInt(adminOrderState.orderInfo.price, 10).toLocaleString() }} NTD
                          </p>
                        </div>

                        <div class="invoice-date-wrapper">
                          <p class="invoice-date-title">
                            繳費方式：
                          </p>
                          <div>
                            <div v-if="adminOrderState.orderInfo.payment_id === null">
                              <p class="text-muted invoice-date">
                                尚未設置
                              </p>
                            </div>

                            <div v-else>
                              <div v-if="adminOrderState.orderInfo.payment_id === 0">
                                <p class="invoice-date">
                                  手動開單
                                </p>
                              </div>

                              <div v-else>
                                <p class="invoice-date">
                                  <span v-if="adminOrderState.orderInfo.payment_id">{{ ui.paymentType[adminOrderState.orderInfo.payment.type] }}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="invoice-date-wrapper">
                          <p class="invoice-date-title">
                            繳費時間：
                          </p>
                          <p class="invoice-date">
                            {{ adminOrderState.orderInfo.bill_time ? moment(adminOrderState.orderInfo.bill_time).format('YYYY/MM/DD HH:mm') : '---' }}
                          </p>
                        </div>
                      </div>

                      <div v-else>
                        <div class="invoice-date-wrapper">
                          <p class="invoice-date-title">
                            付款金額：
                          </p>
                          <p class="invoice-date">
                            ---
                          </p>
                        </div>

                        <div class="invoice-date-wrapper">
                          <p class="invoice-date-title">
                            繳費方式：
                          </p>
                          <div>
                            <p class="invoice-date">
                              ---
                            </p>
                          </div>
                        </div>

                        <div class="invoice-date-wrapper">
                          <p class="invoice-date-title">
                            繳費時間：
                          </p>
                          <p class="invoice-date">
                            ---
                          </p>
                        </div>
                      </div>

                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- 購買明細 -->
              <b-table
                responsive
                :items="adminOrderState.orderInfo ? adminOrderState.orderInfo.product : []"
                :fields="[
                  { label: '編號', key: 'index' },
                  { label: '種類', key: 'product_type' },
                  { label: '商品', key: 'product_info' },
                  { label: '數量', key: 'quantity' },
                  { label: '單位', key: 'unit' },
                  { label: '售價', key: 'price' },
                  { label: '狀態', key: 'state' },
                  { label: '動作', key: 'show_details' },
                ]"
                show-empty
                details-td-class="p-0"
                :busy="adminOrderState.isBusyLoading"
                :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                <!-- 忙碌中 -->
                <template #table-busy>
                  <b-skeleton-table
                    :rows="5"
                    :columns="8"
                    hide-header
                    :table-props="{ bordered: true, striped: true }"
                  />
                </template>

                <!-- 查無資料 -->
                <template #empty>
                  <div class="text-center my-5 animate__animated animate__fadeIn">
                    <b-img
                      :src="$store.state.app.themeImages.notFoundImg"
                      fluid
                      width="480"
                      alt="查無資料"
                    />
                  </div>
                </template>

                <!-- 欄位: 序號 -->
                <template #cell(index)="data">
                  <div class="table-col">
                    {{ data.index + 1 }}
                  </div>
                </template>

                <!-- 欄位: 種類 -->
                <template #cell(product_info)="data">
                  <div class="table-col">
                    <div class="cursor-pointer d-flex align-items-center justify-content-between">
                      <b-link
                        class="font-weight-bold mb-0"
                        @click="data.toggleDetails"
                      >
                        <div class="mb-0">
                          <div class="item-description server-name">
                            {{ ui.productType[data.item.type.toLowerCase()] }}
                          </div>
                          <small class="text-muted">
                            <div v-if="data.item.product_type === 'domain'">
                              {{ data.item.info.prefix }}{{ data.item.info.suffix }}
                            </div>

                            <div v-if="data.item.product_type === 'vps'" />

                            <div v-if="data.item.product_type === 'host'" />
                          </small>
                        </div>
                      </b-link>
                    </div>
                  </div>
                </template>

                <!-- 欄位: 商品 -->
                <template #cell(product_type)="data">
                  <div class="table-col">
                    {{ ui.product[data.item.product_type] }}
                  </div>
                </template>

                <!-- 欄位: 數量 -->
                <template #cell(quantity)="data">
                  <div class="table-col">
                    <!-- {{ data.index + 1 }} -->
                    <div v-if="data.item.product_type === 'domain'">
                      1
                    </div>

                    <div v-if="data.item.product_type === 'vps'" />

                    <div v-if="data.item.product_type === 'host'" />
                  </div>
                </template>

                <!-- 欄位: 單位 -->
                <template #cell(unit)="data">
                  <div class="table-col">
                    <div v-if="data.item.product_type === 'domain'">
                      {{ data.item.info.quantity }}年
                    </div>

                    <div v-if="data.item.product_type === 'vps'" />

                    <div v-if="data.item.product_type === 'host'" />
                  </div>
                </template>

                <!-- 欄位: 售價 -->
                <template #cell(price)="data">
                  <div class="table-col text-nowrap">
                    $ {{ parseInt(data.item.price, 10).toLocaleString() }}
                  </div>
                </template>

                <!-- 欄位: 狀態 -->
                <template #cell(state)="data">
                  <div class="table-col">
                    <b-badge
                      :variant=" resolveState(adminOrderState.orderInfo.state, data.item.state).variant"
                      @click="linkService(data.item)"
                    >
                      {{ resolveState(adminOrderState.orderInfo.state, data.item.state).text }}
                    </b-badge>
                  </div>
                </template>

                <template #cell(show_details)="data">
                  <div class="table-col d-flex mb-50">
                    <div
                      v-if="!isProductBusy.includes(data.index) && data.item.state === 2 && adminOrderState.orderInfo.state === 4"
                      class="actions-link-btn mr-25"
                      @click="onSubmitActivate(data.item, data.index)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="手動開通"
                        src="/dashboard/admin/images/table/product.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>

                    <div
                      v-if="data.item.state !== 0 || adminOrderState.orderInfo.state >= 4"
                      class="actions-link-btn mr-25"
                      @click="linkService(data.item)"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        title="前往服務"
                        src="/dashboard/admin/images/table/external-link.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>

                    <div
                      class="actions-link-btn mr-25"
                      @click="data.toggleDetails"
                    >
                      <b-img
                        v-b-tooltip.hover.focus.v-secondary
                        :title="`${data.detailsShowing ? '隱藏' : '顯示'}詳情`"
                        src="/dashboard/admin/images/table/menu.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </div>
                  </div>
                </template>

                <!-- 欄位: 詳細 -->
                <template #row-details="data">
                  <div class="table-toggle-detail border-top p-1 animate__animated animate__fadeIn">
                    <b-card class="m-0">
                      <h5>詳細內容</h5>

                      <component-domain
                        v-if="data.item.product_type === 'domain'"
                        :order-state="adminOrderState.orderInfo.state"
                        :product-data="data.item"
                      />
                    </b-card>
                  </div>
                </template>
              </b-table>

              <!-- 折扣/總金額 -->
              <b-card-body class="invoice-padding pb-0 mt-3">
                <b-row>
                  <!-- 折扣 -->
                  <b-col
                    cols="12"
                    lg="7"
                    class="mt-md-0 d-flex align-items-center"
                    order="1"
                    order-md="1"
                  />

                  <!-- 金額計算 -->
                  <b-col
                    cols="12"
                    lg="5"
                    class="mt-md-6 d-flex justify-content-end"
                    order="2"
                    order-md="2"
                  >
                    <div
                      v-if="!adminOrderState.isBusyLoading"
                      class="invoice-total-wrapper"
                    >
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          小計：
                        </p>
                        <p class="invoice-total-amount font-weight-normal">
                          ${{ parseInt(calcProductSubTotalPrice(adminOrderState.orderInfo.product), 10).toLocaleString() }} NTD
                        </p>
                      </div>
                      <div
                        v-if="calcAdminOrderPrice() !== 0"
                        class="invoice-total-item"
                      >
                        <p class="invoice-total-title">
                          限定優惠：
                        </p>
                        <div class="invoice-total-amount text-danger font-weight-normal">
                          -${{ calcAdminOrderPrice().toLocaleString() }} NTD
                        </div>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          點數：
                        </p>
                        <div class="invoice-total-amount">
                          <span
                            v-if="adminOrderState.orderInfo.point"
                            class="text-danger font-weight-normal"
                          >
                            -${{ parseInt((adminOrderState.orderInfo.point || 0) * -1, 10).toLocaleString() }} NTD
                          </span>
                          <span v-else>---</span>
                        </div>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          折扣：
                        </p>
                        <div class="invoice-total-amount">
                          ---
                        </div>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          總金額：
                        </p>
                        <p class="invoice-total-amount">
                          ${{ parseInt(adminOrderState.orderInfo.price, 10).toLocaleString() }} NTD
                        </p>
                      </div>
                    </div>

                    <div
                      v-else
                      class="invoice-total-wrapper"
                    >
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          小計：
                        </p>
                        <p class="invoice-total-amount">
                          ---
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          折扣：
                        </p>
                        <div class="invoice-total-amount">
                          ---
                        </div>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          總金額：
                        </p>
                        <p class="invoice-total-amount">
                          $0 NTD
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- 備註 -->
              <b-card-body class="invoice-padding pt-0">
                <div v-if="editCol === 'content' && !adminOrderState.isBusyLoading">
                  <div class="font-weight-bold mb-25 d-flex justify-content-between w-100">
                    <div>備註:</div>

                    <div
                      v-show="!adminOrderState.isBusyLoading"
                      class="mb-50"
                    >
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                        width="18"
                        class="mr-75 cursor-pointer"
                        @click="confirmEditCol"
                      />

                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                        width="18"
                        class="cursor-pointer"
                        @click="cancelEditCol"
                      />
                    </div>
                  </div>
                  <b-form-textarea
                    v-model="editColData"
                    :placeholder="adminOrderState.orderInfo.content ? adminOrderState.orderInfo.content : '感謝您的支持及購買，如有任何疑問，請隨時聯絡我們。'"
                  />
                </div>

                <div v-else>
                  <div class="font-weight-bold mb-25 d-flex justify-content-between w-100">
                    <div>備註:</div>

                    <div v-if="checkAuthAbility('order', 'API.Admin.Order.Update')">
                      <div
                        v-show="!adminOrderState.isBusyLoading"
                        class="actions-link-btn mr-25 invoice-actions"
                        @click="selectEditCol('content')"
                      >
                        <b-img
                          v-b-tooltip.hover.focus.v-secondary
                          title="編輯"
                          src="/dashboard/admin/images/table/edit.svg"
                          class="actions-link-btn-image"
                          rounded
                        />
                      </div>
                    </div>
                  </div>

                  <span class="font-weight-bold">
                    {{ adminOrderState.orderInfo && adminOrderState.orderInfo.content ? adminOrderState.orderInfo.content : '感謝您的支持及購買，如有任何疑問，請隨時聯絡我們。' }}
                  </span>
                </div>
              </b-card-body>

            </b-card>
          </b-overlay>
        </b-col>

        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions mt-md-0 mt-2"
        >
          <b-card v-if="!adminOrderState.isBusyLoading">
            <div class="link-button-container">
              <div
                class="link-botton text-center"
                @click="printInvoice"
              >
                <b-img
                  :src="require('@/assets/images/pages/order/printer.svg')"
                  class="link-botton-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  訂單預覽
                </h5>
              </div>

              <div
                v-if="checkAuthAbility('order', 'API.Admin.Order.Log')"
                class="link-botton text-center"
                @click="onSubmitHistoryLog"
              >
                <b-img
                  :src="require('@/assets/images/pages/order/history.svg')"
                  class="link-botton-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  歷史紀錄
                </h5>
              </div>

              <div
                class="link-botton text-center"
                @click="refreshOrderInfoData"
              >
                <b-img
                  :src="require('@/assets/images/pages/order/refresh.svg')"
                  class="link-botton-image"
                  fluid
                  rounded
                />

                <h5 class="text-body-heading mb-0">
                  刷新訂單
                </h5>
              </div>

              <div
                v-if="checkAuthAbility('remark', 'API.Admin.Remark')"
                class="link-botton text-center"
                @click="onSubmitRemark()"
              >
                <div class="link-botton-alert">
                  <b-img
                    :src="require('@/assets/images/pages/order/remark.svg')"
                    class="link-botton-image"
                    fluid
                    rounded
                  />
                  <div
                    v-if="adminOrderState.orderInfo.remark_display"
                    class="state-dot dot-red"
                  />
                </div>

                <h5 class="text-body-heading mb-0">
                  留言備註
                </h5>
              </div>
            </div>
          </b-card>

          <div v-if="!adminOrderState.isBusyLoading && checkAuthAbility('order', 'API.Admin.Order.Id.Completed')">
            <div v-if="adminOrderState.orderInfo.state === 0 && adminOrderState.orderInfo.payment_id === 0">
              <div
                v-if="checkAuthAbility('order', 'API.Admin.Order.Id.Pay')"
                class="action-button pay-button"
                :class="{ 'action-button-disable': isInfoBusy }"
                @click="submitUsePointCheck()"
              >
                <span
                  v-if="isInfoBusy"
                  class="d-flex align-items-center justify-content-center"
                >
                  <b-spinner
                    small
                    class="mr-50"
                  />
                  處理中
                </span>
                <span v-else>{{ isOrderManualFree() ? '確認訂單' : '手動付款' }}</span>
                <span class="button-border" />
              </div>
            </div>

            <div
              v-if="isOrderCanCancel()"
              class="action-button cancel-button"
              :class="{ 'action-button-disable': isInfoBusy }"
              @click="submitCancel"
            >
              <span
                v-if="isInfoBusy"
                class="d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  small
                  class="mr-50"
                />
                處理中
              </span>
              <span v-else>取消訂單</span>
              <span class="button-border" />
            </div>

            <div
              v-if="adminOrderState.orderInfo.state === 4 && adminOrderState.orderInfo.product.every(item => item.state !== 0)"
              class="action-button cancel-button"
              :class="{ 'action-button-disable': isInfoBusy }"
              @click="submitUseRefundCheck('refund')"
            >
              <span
                v-if="isInfoBusy"
                class="d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  small
                  class="mr-50"
                />
                處理中
              </span>
              <span v-else>訂單退款</span>
              <span class="button-border" />
            </div>

            <div
              v-if="adminOrderState.orderInfo.state === 4 && adminOrderState.orderInfo.product.every(item => item.state !== 0)"
              class="action-button complete-button"
              :class="{ 'action-button-disable': isInfoBusy }"
              @click="submitUseRefundCheck('complete')"
            >
              <span
                v-if="isInfoBusy"
                class="d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  small
                  class="mr-50"
                />
                處理中
              </span>
              <span v-else>完成訂單</span>
              <span class="button-border" />
            </div>
          </div>
        </b-col>
      </b-row>
    </section>

    <!-- 訂單紀錄 -->
    <order-history-modal
      v-if="!adminOrderState.isBusyLoading"
      ref="historyModal"
      :order-id="adminOrderState.orderInfo.ssid"
    />

    <b-modal
      v-if="adminOrderState.orderInfo"
      id="modal-user-point"
      ok-title="確認"
      ok-only
      centered
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          使用錢包
        </h4>
      </template>

      <div class="modal-point-content">
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <div
              class="link-card text-center"
              :class="{'link-card-active': usePoint === 1 }"
              @click="() => usePoint = 1"
            >
              <b-img
                :src="require('@/assets/images/pages/order/point-wallet.svg')"
                class="link-card-image"
                fluid
                rounded
              />

              <h5 class="text-body-heading mb-0">
                錢包折抵
              </h5>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="6"
          >
            <div
              class="link-card text-center"
              :class="{'link-card-active': usePoint === 0 }"
              @click="() => usePoint = 0"
            >
              <b-img
                :src="require('@/assets/images/pages/order/point-cancel.svg')"
                class="link-card-image"
                fluid
                rounded
              />

              <h5 class="text-body-heading mb-0">
                不使用
              </h5>
            </div>
          </b-col>
        </b-row>

        <div
          v-if="usePoint === 1"
          class="animate__animated animate__fadeIn animate__slow"
        >
          <div class="price-setting-radio-wrapper">
            <label class="label">
              <input
                v-model="pointType"
                :value="0"
                name="price-setting-radio"
                class="radio-input"
                type="radio"
              >
              <div class="radio-design" />
              <div class="label-text">優先使用錢包金額折抵
                <small>(錢包點數 ${{ parseInt(adminOrderState.orderInfo.customer_info.point || 0, 10).toLocaleString() }})</small>
              </div>
            </label>
            <label class="label">
              <input
                v-model="pointType"
                :value="1"
                name="price-setting-radio"
                class="radio-input"
                type="radio"
              >
              <div class="radio-design" />
              <div class="label-text">
                自訂折抵金額
                <!-- <small v-if="paymentData">
                  (當前金流上下限 ${{ parseInt(paymentData.low, 10).toLocaleString() }} ~ ${{ parseInt(paymentData.high, 10).toLocaleString() }})
                </small> -->
              </div>
            </label>
          </div>

          <div
            v-if="pointType === 1"
            class="mt-50 ml-50 animate__animated animate__fadeIn"
          >
            <input
              v-model="formattedPointValue"
              type="text"
              class="point-value-input"
              :placeholder="`請輸入折抵金額，最大使用上限 ${calcMaxUsePoint().toLocaleString()} 點`"
              @input="onInputPoint"
              @blur="onBlurPoint"
              @keydown="onKeyDownPoint"
            >
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            :disabled="usePoint === null || isInfoBusy"
            @click="submitUsePointConfirm"
          >
            <div v-show="isInfoBusy">
              <div class="busy-text">
                <b-spinner small />
              </div>
            </div>

            <div v-show="!isInfoBusy">
              <div class="busy-text">
                確認
              </div>
            </div>
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      v-if="adminOrderState.orderInfo"
      id="modal-user-refund"
      ok-title="確認"
      ok-only
      centered
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          訂單退款
        </h4>
      </template>

      <div class="modal-point-content">
        <div class="mt-50 ml-50 animate__animated animate__fadeIn">
          <input
            v-model="formattedRefundValue"
            type="text"
            class="point-value-input"
            :placeholder="`請輸入退款金額，退款上限 $${calcMaxUseRefund().toLocaleString()} NTD`"
            @input="onInputRefund"
            @blur="onBlurRefund"
            @keydown="onKeyDownRefund"
          >
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            :disabled="isInfoBusy"
            @click="submitUseRefundConfirm"
          >
            <div v-show="isInfoBusy">
              <div class="busy-text">
                <b-spinner small />
              </div>
            </div>

            <div v-show="!isInfoBusy">
              <div class="busy-text">
                確認
              </div>
            </div>
          </b-button>
        </div>
      </template>
    </b-modal>

    <remark-modal
      v-if="adminOrderState.orderInfo"
      ref="orderRemarkExplorerModal"
      remark-explorer-id="orderRemarkExplorerModal"
      :key-array="keyArray"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="refreshOrderInfoData"
      @call-back-data="() => {}"
    >
      <template v-slot:header-info>
        <div class="mb-1" />
      </template>
    </remark-modal>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BButton, BCardBody, BImg, BTable, BLink, BBadge, BSpinner, VBTooltip,
  BFormTextarea, BSkeletonTable, BOverlay,
} from 'bootstrap-vue'
import moment from 'moment'

import store from '@/store'
import router from '@/router'
import useStoreModule from '../useStoreModule'
import { useOrderView, useOrderSetting } from '../useOrder'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import ComponentDomain from './components/domain/DomainDetails.vue'
import OrderHistoryModal from '../components/HistoryModal.vue'
import remarkModal from '@/layouts/components/Modal/remark-modal/remarkModal.vue'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BBadge,
    BCardBody,
    BTable,
    BSpinner,
    BButton,
    BLink,
    BOverlay,
    BSkeletonTable,
    BFormTextarea,
    ComponentDomain,
    OrderHistoryModal,
    remarkModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      title: router.currentRoute.meta.pageTitle,
      keyArray: [],
      isProductBusy: [],
      editCol: null,
      editColData: null,
    }
  },
  computed: {
    adminOrderState() {
      return this.$store.state['admin-order']
    },
    formattedPointValue: {
      get() {
        return this.formatCurrencyPoint(this.pointNumber)
      },
      set(val) {
        this.pointNumber = this.unformatCurrencyPoint(val)
      },
    },
    formattedRefundValue: {
      get() {
        return this.formatCurrencyRefund(this.refundNumber)
      },
      set(val) {
        this.refundNumber = this.unformatCurrencyRefund(val)
      },
    },
  },
  methods: {
    moment,
    // (觸發)備註
    onSubmitRemark() {
      const auth = this.checkAuthAbility('remark', 'API.Admin.Remark')
      if (!auth) return
      const resolveArray = []
      resolveArray.push(`order-${this.adminOrderState.orderInfo.id}`)
      if (this.adminOrderState.orderInfo.customer_info) resolveArray.push(`customer-${this.adminOrderState.orderInfo.customer_info.id}`)
      this.keyArray = resolveArray
      this.selected = JSON.parse(JSON.stringify(this.adminOrderState.orderInfo))
      setTimeout(() => { this.$refs.orderRemarkExplorerModal.getData() }, 200)
    },

    // (解析)狀態顯示
    resolveState(orderState, state) {
      if (orderState === 5 || orderState === 4) {
        const productState = [{
          0: '處理中', 1: '完成', 2: '失敗', 3: '已退款',
        }, {
          0: 'light-secondary', 1: 'light-success', 2: 'light-danger', 3: 'light-primary',
        }]
        return {
          text: productState[0][state],
          variant: productState[1][state],
        }
      }
      if (orderState === 3) {
        return {
          text: '取消',
          variant: 'light-secondary',
        }
      }
      if (orderState === 2) {
        return {
          text: '已退款',
          variant: 'light-info',
        }
      }
      if (orderState === 1) {
        return {
          text: '交易中',
          variant: 'light-secondary',
        }
      }
      if (orderState === 0) {
        return {
          text: '未繳費',
          variant: 'light-secondary',
        }
      }
      return {
        text: '錯誤',
        variant: 'light-danger',
      }
    },

    // (觸發)訂單紀錄
    onSubmitHistoryLog() {
      const auth = this.checkAuthAbility('order', 'API.Admin.Order.Log')
      if (!auth) return
      setTimeout(() => { this.$refs.historyModal.getData() }, 200)
    },

    // (觸發)服務執行
    onSubmitActivate(product, index) {
      const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.Action')
      if (!auth) return
      if (product.state !== 2) return
      if (this.adminOrderState.orderInfo.state !== 4) return
      if (!product.id) return
      if (this.isProductBusy.includes(index)) return

      switch (product.product_type) {
        case 'domain': {
          const productInfo = `${this.ui.productType[product.type.toLowerCase()]} ${product.info.prefix}${product.info.suffix} / ${product.info.quantity}年`
          this.useSwalAlertWarning('網域串接', `你確定要手動觸發 ${productInfo} 的串接服務嗎? ( 該動作包含網域購買流程及服務新增，請先確認網域商餘額，以免購買失敗 )`)
            .then(result => {
              if (result.value) {
                if (!this.isProductBusy.includes(index)) {
                  this.isProductBusy.push(index)
                }
                this.setOrderServiceDomain({
                  domain_name_id: product.id,
                }).then(response => {
                  this.isProductBusy = this.isProductBusy.filter(id => id !== index)
                  this.useSwalAlertCenter(true, '串接成功', response.data.message)
                })
                  .catch(error => {
                    this.useSwalAlertCenter(false, '串接失敗', error.response.data.message)
                    this.isProductBusy = this.isProductBusy.filter(id => id !== index)
                  })
              }
            })
        }
          break
        case 'vps':
          break
        case 'host':
          break
        default:
          break
      }
    },

    // (前往)服務
    linkService(product) {
      if (product.state === 0) return
      switch (product.product_type) {
        case 'domain': {
          const auth = this.checkAuthAbility('service', 'API.Admin.DomainName.Service.Url')
          if (!auth) return
          const [punycodeString] = new URL(`http://${product.info.prefix}${product.info.suffix}`).hostname.split('.')
          this.$router.push({ name: 'admin-service-domain-view', params: { id: `${punycodeString}${product.info.suffix}` } })
          break
        }
        case 'vps':
          break
        case 'host':
          break
        default:
          break
      }
    },

    // (前往)訂單建立者
    linkCreatorInfo(type, id) {
      if (type === 'admin') {
        const auth = this.checkAuthAbility('admin', 'API.Admin.User.Id')
        if (!auth) return
        this.$router.push({ name: 'admin-user-view', params: { id } })
        return
      }

      if (type === 'client') {
        const auth = this.checkAuthAbility('customer', 'API.Admin.Customer.Id')
        if (!auth) return
        this.$router.push({ name: 'admin-customer-view', params: { id } })
      }
    },

    // (選取)編輯欄位
    selectEditCol(type) {
      this.editCol = type
      this.editColData = JSON.parse(JSON.stringify(this.adminOrderState.orderInfo[type]))
    },

    // (取消)編輯欄位
    cancelEditCol() {
      this.editCol = null
    },

    // (訂單)確認編輯
    confirmEditCol() {
      const auth = this.checkAuthAbility('order', 'API.Admin.Order.Update')
      if (!auth) return
      if (this.isInfoBusy) return
      this.isInfoBusy = true
      this.setOrderUpdate({
        order_id: this.adminOrderState.orderInfo.id,
        content: this.editColData,
        tag_id: this.adminOrderState.orderInfo.tag_id || [],
      })
        .then(response => {
          this.adminOrderState.orderInfo[this.editCol] = this.editColData
          this.editCol = null
          this.useSwalAlertCenter(true, '更新成功', response.data.message)
          this.isInfoBusy = false
        })
        .catch(error => {
          this.useSwalAlertCenter(false, '動作失敗', error.response.data.message)
          this.isInfoBusy = false
        })
    },

    // (判斷)訂單商品是否可操作
    isOrderOperable() {
      let state = true
      this.adminOrderState.orderInfo.product.forEach(item => {
        if (item.type === 'domain_new' && item.state === 1) {
          state = false
        }
      })
      return state
    },

    // (判斷)訂單是否可取消
    isOrderCanCancel() {
      let state = false
      if (this.adminOrderState.orderInfo.point !== 0) return false

      const isAllFail = this.adminOrderState.orderInfo.product.every(item => item.state === 2)

      if (this.adminOrderState.orderInfo.state === 4) {
        if (isAllFail && this.adminOrderState.orderInfo.payment_id === 0) {
          if (this.adminOrderState.orderInfo.price === 0) {
            state = true
          }
        }
      }

      if (this.adminOrderState.orderInfo.state === 0 && !this.adminOrderState.orderInfo.payment_id) {
        state = true
      }
      return state
    },

    // (判斷)訂單是否可退款
    isOrderCanRefund() {
      let state = false
      const isAllFail = this.adminOrderState.orderInfo.product.every(item => item.state === 2)

      state = isAllFail

      if (this.adminOrderState.orderInfo.state === 4) {
        if (this.adminOrderState.orderInfo.price === 0) {
          return false
        }
      }
      return state
    },

    // (判斷)是否為手動開0元單
    isOrderManualFree() {
      let state = false
      if (this.adminOrderState.orderInfo.state === 0 && this.adminOrderState.orderInfo.payment_id === 0 && this.adminOrderState.orderInfo.price === 0) {
        state = true
      }
      return state
    },

    // ---------------------------------------------------------------------------------------------
    formatCurrencyPoint(value) {
      if (!value) return ''
      return parseFloat(value)
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    unformatCurrencyPoint(value) {
      return value.replace(/[^\d]/g, '')
    },

    // (計算)最大可用餘額
    calcMaxUsePoint() {
      const orderPrice = parseInt(this.adminOrderState.orderInfo.price, 10)
      const userPoint = parseInt(this.adminOrderState.orderInfo.customer_info.point || 0, 10)
      if (userPoint > orderPrice) return orderPrice
      return userPoint
    },

    onInputPoint(event) {
      let newValue = this.unformatCurrencyPoint(event.target.value)
      const canUsePoint = this.calcMaxUsePoint()
      if (newValue > canUsePoint) {
        newValue = canUsePoint
      } else if (newValue < 0) {
        newValue = 0
      }
      this.pointNumber = newValue
    },

    onBlurPoint() {
      const canUsePoint = this.calcMaxUsePoint()
      this.pointNumber = Math.min(Math.max(parseFloat(this.pointNumber) || 0, 0), canUsePoint)
    },

    onKeyDownPoint(event) {
      if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
        event.preventDefault()
      }
    },

    // (詢問)是否使用點數
    submitUsePointCheck() {
      if (this.isInfoBusy) return

      const state = this.isOrderManualFree()

      if (state) {
        this.submitPay(0)
        return
      }

      const userPoint = parseInt(this.adminOrderState.orderInfo.customer_info.point || 0, 10)
      if (userPoint) {
        this.$bvModal.show('modal-user-point')
        return
      }
      this.submitPay(0)
    },

    // (觸發)餘額折抵
    submitUsePointConfirm() {
      if (!this.usePoint) {
        this.$bvModal.hide('modal-user-point')
        this.submitPay(0)
        return
      }
      const canUsePoint = this.calcMaxUsePoint()
      const orderPrice = parseInt(this.adminOrderState.orderInfo.price, 10)
      if (this.pointType === 0) {
        this.$bvModal.hide('modal-user-point')
        this.submitPay(canUsePoint)
        return
      }
      if (this.pointType === 1) {
        if (!this.pointNumber) {
          this.useSwalAlertCenter(false, '折抵失敗', '折抵金額不可為空')
          return
        }

        if (this.pointNumber >= orderPrice) {
          this.$bvModal.hide('modal-user-point')
          this.submitPay(this.pointNumber)
          return
        }

        this.$bvModal.hide('modal-user-point')
        this.submitPay(this.pointNumber)
      }
    },

    // (手動)觸發付款
    submitPay(point) {
      const auth = this.checkAuthAbility('order', 'API.Admin.Order.Id.Pay')
      if (!auth) return
      if (this.isInfoBusy) return
      const state = this.isOrderManualFree()
      this.isInfoBusy = true

      let payInfo = '此訂單為手動付款，請確認已收取相關服務款項 （確認訂單後將加入自動購買排程）'
      if (state) payInfo = '此訂單無任何款項，確認訂單後將加入自動購買排程'

      this.useSwalAlertInfo('操作確認', `${payInfo}`)
        .then(result => {
          if (result.value) {
            this.isInfoBusy = false
            this.setOrderPay({
              order_id: this.adminOrderState.orderInfo.id,
              point,
            })
              .then(() => {
                this.refreshOrderInfoData()
                this.useSwalAlertCenter(true, '操作成功', '訂單商品處理中，請稍後確認')
                this.isInfoBusy = false
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '操作失敗', error.response.data.message)
                this.isInfoBusy = false
              })
          } else this.isInfoBusy = false
        })
    },

    // ---------------------------------------------------------------------------------------------
    formatCurrencyRefund(value) {
      if (value === 0) return 0
      if (!value) return ''
      return parseFloat(value)
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    unformatCurrencyRefund(value) {
      return value.replace(/[^\d]/g, '')
    },

    // (計算)最大可用退款
    calcMaxUseRefund() {
      const orderPrice = parseInt(this.adminOrderState.orderInfo.price, 10)
      const orderPoint = parseInt(this.adminOrderState.orderInfo.point ? this.adminOrderState.orderInfo.point * -1 : 0, 10)
      return orderPrice + orderPoint
    },

    onInputRefund(event) {
      let newValue = this.unformatCurrencyRefund(event.target.value)
      const canUseRefund = this.calcMaxUseRefund()
      if (newValue > canUseRefund) {
        newValue = canUseRefund
      } else if (newValue <= 0) {
        newValue = 0
      }
      this.refundNumber = newValue
    },

    onBlurRefund() {
      const canUseRefund = this.calcMaxUseRefund()
      this.refundNumber = Math.min(Math.max(parseFloat(this.refundNumber) || 0, 0), canUseRefund)
    },

    onKeyDownRefund(event) {
      if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
        event.preventDefault()
      }
    },

    // ---------------------------------------------------------------------------------------------
    // (詢問)是否使用退款
    submitUseRefundCheck(type) {
      if (this.isInfoBusy) return

      const isAllFinish = this.adminOrderState.orderInfo.product.every(item => item.state !== 0)
      const isAllFail = this.adminOrderState.orderInfo.product.every(item => item.state === 2)
      const hasFailed = this.adminOrderState.orderInfo.product.some(item => parseInt(item.state, 10) === 2)

      if (!isAllFinish) {
        this.useSwalAlertCenter(false, '動作失敗', '尚有訂單商品仍在進行處理')
        this.isInfoBusy = false
        return
      }

      if (type === 'refund') {
        if (!isAllFail) {
          this.$bvModal.show('modal-user-refund')
          return
        }

        const canUseRefund = this.calcMaxUseRefund()
        this.submitRefund(isAllFail, canUseRefund)
        return
      }

      if (type === 'complete') {
        this.isInfoBusy = true

        if (isAllFail) {
          this.useSwalAlertCenter(false, '動作失敗', '訂單商品中"無"包含交易成功商品，請使用訂單退款操作本訂單')
          this.isInfoBusy = false
          return
        }

        const resolve = {
          order_id: this.adminOrderState.orderInfo.id,
        }

        let suuccessInfo = '訂單商品已完成處理，是否確認完成訂單?'
        if (hasFailed) {
          suuccessInfo = '此筆訂單包含部分交易失敗商品，是否不進行任何退款服務，立即完成此訂單?'
          resolve.money = 0
        }

        this.useSwalAlertInfo('完成訂單', `${suuccessInfo}`)
          .then(result => {
            if (result.value) {
              this.setOrderCompleted(resolve)
                .then(() => {
                  this.refreshOrderInfoData()
                })
                .catch(error => {
                  this.useSwalAlertCenter(false, '動作失敗', error.response.data.message)
                  this.isInfoBusy = false
                })
            } else this.isInfoBusy = false
          })
      }
    },

    // (觸發)確認退款
    submitUseRefundConfirm() {
      if (this.refundNumber === null || this.refundNumber === '') {
        this.useSwalAlertCenter(false, '操作失敗', '退款金額不可為空')
        return
      }

      this.$bvModal.hide('modal-user-refund')
      this.submitRefund(false, this.refundNumber)
    },

    // (取消)訂單
    submitCancel() {
      const auth = this.checkAuthAbility('order', 'API.Admin.Order.Id.Completed')
      if (!auth) return
      if (this.isInfoBusy) return
      const state = this.isOrderCanCancel()
      if (!state) return
      this.isInfoBusy = true
      if (this.adminOrderState.orderInfo.state === 0) {
        this.useSwalAlertInfo('取消訂單', '是否取消此筆待確認的訂單')
          .then(result => {
            if (result.value) {
              this.setOrderCompleted({
                order_id: this.adminOrderState.orderInfo.id,
              })
                .then(() => {
                  this.refreshOrderInfoData()
                })
                .catch(error => {
                  this.useSwalAlertCenter(false, '取消失敗', error.response.data.message)
                  this.isInfoBusy = false
                })
            } else this.isInfoBusy = false
          })
        return
      }

      const isAllFinish = this.adminOrderState.orderInfo.product.every(item => item.state !== 0)
      if (!isAllFinish) {
        this.useSwalAlertCenter(false, '動作失敗', '尚有訂單商品仍在進行處理')
        this.isInfoBusy = false
        return
      }

      this.useSwalAlertInfo('取消訂單', '此筆訂單為手動開單，將自動關閉相關服務')
        .then(result => {
          if (result.value) {
            this.setOrderCompleted({
              order_id: this.adminOrderState.orderInfo.id,
            })
              .then(() => {
                this.$router.push({ name: 'admin-order' })
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '取消失敗', error.response.data.message)
                this.isInfoBusy = false
              })
          } else this.isInfoBusy = false
        })
    },

    // (退款)訂單
    submitRefund(tpye, money) {
      const auth = this.checkAuthAbility('order', 'API.Admin.Order.Id.Completed')
      if (!auth) return
      this.isInfoBusy = true
      let refundInfo = `訂單商品中"無"包含交易成功商品，訂單退款動作會將款項 $${money.toLocaleString()} 全數轉移至會員錢包`
      if (!tpye) refundInfo = `此筆訂單包含部分交易失敗商品，將退款 $${money.toLocaleString()} 至會員錢包，請確認退款相關服務已處理完畢`
      this.useSwalAlertInfo('訂單退款', `${refundInfo}`)
        .then(result => {
          if (result.value) {
            this.setOrderCompleted({
              order_id: this.adminOrderState.orderInfo.id,
              money,
            })
              .then(() => {
                this.refreshOrderInfoData()
              })
              .catch(error => {
                this.useSwalAlertCenter(false, '退款失敗', error.response.data.message)
                this.isInfoBusy = false
              })
          } else this.isInfoBusy = false
        })
    },

    // (計算)購物車金額相加
    calcProductSubTotalPrice(productData) {
      const filteredData = productData.filter(item => item.price !== null)
      const totalPrice = filteredData.reduce((total, item) => total + parseInt(item.price, 10), 0)
      return totalPrice
    },

    // (計算)限定優惠
    calcAdminOrderPrice() {
      const subTotalPrice = this.calcProductSubTotalPrice(this.adminOrderState.orderInfo.product)
      const orderPrice = parseInt(this.adminOrderState.orderInfo.price, 10)
      const orderPoint = parseInt(this.adminOrderState.orderInfo.point ? this.adminOrderState.orderInfo.point * -1 : 0, 10)
      return subTotalPrice - orderPrice - orderPoint
    },

    // (計算)購物車總金額
    calcProductTotalPrice() {
      const subTotal = this.calcProductSubTotalPrice(this.adminOrderState.product)
      return subTotal
    },

    // (預覽)帳單
    printInvoice() {
      window.print()
    },
  },
  setup() {
    const ORDER_ADMIN_STORE_MODULE_NAME = 'admin-order'

    if (!store.hasModule(ORDER_ADMIN_STORE_MODULE_NAME)) store.registerModule(ORDER_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(ORDER_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(ORDER_ADMIN_STORE_MODULE_NAME)
    })

    const orderID = router.currentRoute.params.id

    const {
      isInfoBusy,
      usePoint,
      pointType,
      pointNumber,
      refundNumber,
      getOrderInfoData,
    } = useOrderView()

    const {
      ui,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
      setOrderServiceDomain,
      setOrderCompleted,
      setOrderPay,
      setOrderUpdate,
    } = useOrderSetting()

    const {
      useAlertToast,
    } = useAlert()

    const refreshOrderInfoData = () => {
      getOrderInfoData({ order_id: orderID })
      usePoint.value = null
      pointType.value = 0
      pointNumber.value = null
      refundNumber.value = null
    }

    refreshOrderInfoData()

    return {
      ui,
      useAlertToast,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,

      orderID,
      isInfoBusy,
      usePoint,
      pointType,
      pointNumber,
      refundNumber,
      getOrderInfoData,
      refreshOrderInfoData,
      setOrderServiceDomain,
      setOrderCompleted,
      setOrderPay,
      setOrderUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
</style>

<style lang="scss" scoped>
$modal-header-color: #198197;
.dark-layout {
  .modal-point-content {
    .link-card {
      .text-body-heading {
        color: white;
      }
    }

    .point-value-input {
      border: 1px solid #596987;
      color: white;
      background: #344464;
      &:hover {
        border: 1px solid #419fd9;
      }

      &:focus {
        border: 1px solid #419fd9;
      }

      &::placeholder {
        color: #a7a7a7;
      }
    }
  }
}

.table-toggle-detail {
  background-color: rgba(#babfc7, 0.12);
}

.detail-title {
  min-width: 250px;
}

.link-button-container {
  display: flex;
  flex-wrap: wrap;
  .link-botton {
    width: calc(50% - 10px);
    margin: 5px;
    padding: 20px 10px;
    border: 1px solid #d5d2dc1a;
    background-color: #b8b7bb3b;
    margin-bottom: 10px;
    border-radius: 10px;
    transition: transform ease-out 200ms;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .link-botton-image {
      max-width: 30px;
      max-height: 30px;
      margin-bottom: 10px;
    }

    .text-body-heading {
      font-size: 14px;
    }

    &:hover {
      transform: scale(1.05);
      cursor: pointer;
      animation: btn-breathe .8s linear infinite;
      background-color: #c9ebff59;
    }
  }

  .link-botton-alert {
    position: relative;
    width: 100%;
    max-width: 30px;
    .state-dot {
      position: absolute;
      right: -5px;
      bottom: 8px;
    }
  }
}

.action-button {
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  border: none;
  font-size: 1rem;
  font-weight: 400;
  color: #f4f0ff;
  text-align: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 1rem;
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    z-index: -1;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    opacity: 0;
    z-index: -1;
    transition: all 0.3s ease-in;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  .button-border {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    z-index: -1;

    &::before {
      content: "";
      position: absolute;
      border-radius: 0.5rem;
      padding: 1px;
      inset: 0;
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0); /* 添加标准属性 */
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }
  }
}

.complete-button {
  &::before {
    background: linear-gradient(180deg,rgba(8, 77, 126, 0) 0%,rgba(26, 151, 189, 0.42) 100%),rgba(41, 223, 162, 0.4);
    box-shadow: inset 0 0 12px rgba(151, 200, 255, 0.44);
  }

  &::after {
    background: linear-gradient(180deg,rgba(8, 77, 126, 0) 0%,rgba(26, 151, 189, 0.42) 100%),rgba(41, 223, 162, 0.4);
    box-shadow: inset 0 0 12px rgba(151, 200, 255, 0.44);
  }

  .button-border {
    &::before {
      background: linear-gradient(180deg, rgba(41, 223, 162, 0.3) 0%,rgba(86, 184, 184, 0.55) 100%),
      linear-gradient(0deg, rgba(41, 223, 162, 0.3), rgba(86, 184, 184, 0.55));
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    }
  }
}

.pay-button {
  &::before {
    background: linear-gradient(180deg,rgba(8, 77, 126, 0) 0%,rgba(14, 100, 131, 0.42) 100%),rgba(40, 185, 230, 0.4);
    box-shadow: inset 0 0 12px rgba(25, 88, 170, 0.44);
  }

  &::after {
    background: linear-gradient(180deg,rgba(8, 77, 126, 0) 0%,rgba(14, 100, 131, 0.42) 100%),rgba(40, 185, 230, 0.4);
    box-shadow: inset 0 0 12px rgba(25, 88, 170, 0.44);
  }

  .button-border {
    &::before {
      background: linear-gradient(180deg, rgba(25, 118, 199, 0.3) 0%,rgba(11, 189, 189, 0.356) 100%),
      linear-gradient(0deg, rgba(25, 118, 199, 0.3), rgba(11, 189, 189, 0.356));
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    }
  }
}

.cancel-button {
  &::before {
    background: linear-gradient(180deg,rgba(8, 77, 126, 0) 0%,rgba(189, 200, 204, 0.3) 100%),rgba(177, 189, 185, 0.4);
    box-shadow: inset 0 0 12px rgba(96, 103, 112, 0.2);
  }

  &::after {
    background: linear-gradient(180deg,rgba(8, 77, 126, 0) 0%,rgba(189, 200, 204, 0.3) 100%),rgba(177, 189, 185, 0.4);
    box-shadow: inset 0 0 12px rgba(96, 103, 112, 0.2);
  }

  .button-border {
    &::before {
      background: linear-gradient(180deg, rgba(167, 176, 184, 0.3) 0%,rgba(113, 122, 122, 0.356) 100%),
      linear-gradient(0deg, rgba(167, 176, 184, 0.3), rgba(113, 122, 122, 0.356));
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    }
  }
}

.action-button-disable {
  background-color: #a3a3a34f;
  cursor: auto;
  &:hover {
    &::after {
      opacity: 0;
    }
  }
}

.selection-group {
  .selection-btn-icon-show {
    opacity: 0;
    margin-left: 3px;
  }
  &:hover {
  .selection-btn-icon-show {
      opacity: 1;
    }
  }
}

.model-header {
  padding: 5px 0;
}

.modal-point-content {
  .link-card {
    padding: 30px;
    border: 1px solid #d5d2dc1a;
    background-color: #b8b7bb1d;
    margin-bottom: 20px;
    border-radius: 10px;
    transition: transform ease-out 200ms;
    .link-card-image {
      max-width: 60px;
      max-height: 60px;
      margin-bottom: 10px;
    }

    .text-body-heading {
      margin-top: 5px;
      color: $modal-header-color;
      font-size: 18px;
      font-weight: bolder;
    }

    &:hover {
      transform: scale(1.05);
      cursor: pointer;
      .link-card-image {
        animation: breathe .8s linear infinite;
      }
    }
  }

  .link-card-active {
    border: 2px solid #82cfee;
    background-color: #c9ebff59;
  }

  .price-setting-radio-wrapper {
    .label {
      display: flex;
      align-items: center;
      border-radius: 10px;
      padding: 10px 16px;
      margin: 5px 0;
      cursor: pointer;
      transition: .3s;
      &:hover {
        background-color: #b8b7bb1d;
      }
    }

    .radio-input {
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 1px;
      opacity: 0;
      z-index: -1;
    }

    .radio-input:checked+.radio-design::before {
    transform: scale(0);
  }

    .radio-design {
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background: linear-gradient(to right bottom, hsl(180, 65%, 75%), #419fd9);
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        background: hsl(0, 0%, 90%);
        transform: scale(1.1);
        transition: .3s;
      }
    }

    .label-text {
      margin-left: 14px;
      letter-spacing: 2px;
      font-size: 16px;
      font-weight: 500;
      transition: .3s;
    }

  }

  .point-value-input {
    border: 1px solid #d8d6de;
    width: 100%;
    margin: 5px 0;
    padding: 0.438rem 1rem;
    outline: none;
    overflow: hidden;
    border-radius: 0.357rem;
    font-size: 18px;
    color: $modal-header-color;
    font-weight: bolder;
    letter-spacing: 2px;
    &:hover {
      border: 1px solid #82cfee;
    }

    &:focus {
      border: 1px solid #82cfee;
      box-shadow: 0px 0px 0px 1px #4a9dec33;
    }

    &::placeholder {
      font-size: 14px;
      color: #a7a7a7;
      font-weight: 300;
    }
  }
}
</style>

<style lang="scss">
@media print {
  body {
    background-color: transparent !important;
  }
  a {
    text-decoration: none !important;
  }
  .invoice-state {
    display: none !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content-header {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .horizontal-menu-wrapper{
    display: none !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
    .product-service {
      display: none !important;
    }
  }
  .customizer-toggle {
    display: none !important;
  }

  .invoice-add-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(1) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    .invoice-actions {
      display: none;
    }
  }
}

</style>
