<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          label-cols="4"
          label="商品SSID"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ productData.product ? productData.product : '---' }}
          </div>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          label-cols="4"
          label="網域前綴"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ productData.info.prefix ? productData.info.prefix : '---' }}
          </div>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          label-cols="4"
          label="網域後綴"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ productData.info.suffix ? productData.info.suffix : '---' }}
          </div>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          label-cols="4"
          label="服務週期"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ productData.info.quantity ? `${productData.info.quantity}年` : '---' }}
          </div>
        </b-form-group>
      </b-col>

      <b-col
        v-if="productData.type === 'domain_transfer'"
        cols="12"
        lg="6"
      >
        <b-form-group
          label-cols="4"
          label="服務週期"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ productData.info.quantity ? `${productData.info.quantity}年` : '---' }}
          </div>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-form-group
          label-cols="4"
          label="備註"
          class="m-0 selection-group"
        >
          <div class="mt-50">
            {{ productData.comment ? productData.comment : '---' }}
          </div>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      v-if="orderState > 4"
      class="mt-1 product-service"
    >
      <h5>網域服務</h5>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group
            label-cols="4"
            label="服務類型"
            class="m-0 selection-group"
          >
            <div class="mt-50 d-flex align-items-center">
              {{ ui.productType[productData.type.toLowerCase()] }}
              <div
                class="actions-link-btn ml-25"
                @click="linkService"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="前往服務"
                  src="/dashboard/admin/images/table/external-link.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group
            label-cols="4"
            label="服務狀態"
            class="m-0 selection-group"
          >
            <div class="mt-50">
              <b-badge
                v-if="productData.info.end_time && moment(productData.info.end_time).isBefore(moment())"
                :variant="ui.productState[3][2]"
                class="cursor-pointer"
                @click="linkService"
              >
                {{ ui.productState[2][2] }}
              </b-badge>

              <b-badge
                v-else
                :variant="ui.productState[3][productData.info.state]"
                class="cursor-pointer"
                @click="linkService"
              >
                {{ ui.productState[2][productData.info.state] }}
              </b-badge>
            </div>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group
            label-cols="4"
            label="服務啟動日"
            class="m-0 selection-group"
          >
            <div class="mt-50">
              {{ productData.info.start_time ? moment(productData.info.start_time).format('YYYY/MM/DD HH:mm') : '---' }}
            </div>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
        >
          <b-form-group
            label-cols="4"
            label="服務結束日"
            class="m-0 selection-group"
          >
            <div class="mt-50">
              {{ productData.info.end_time ? moment(productData.info.end_time).format('YYYY/MM/DD HH:mm') : '---' }}
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BBadge, BImg, VBTooltip,
} from 'bootstrap-vue'
import moment from 'moment'
import { useOrderSetting } from '../../../useOrder'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BBadge,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    orderState: {
      type: Number,
      required: true,
    },
    productData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    moment,
    // (前往)服務
    linkService() {
      if (!this.productData.id) return
      this.$router.push({ name: 'admin-service-domain-view', params: { id: this.productData.id } })
    },
  },
  setup() {
    const {
      ui,
    } = useOrderSetting()

    return {
      ui,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
