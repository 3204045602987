<template>
  <b-modal
    :id="modalExplorerId"
    ok-title="確認"
    header-bg-variant="primary"
    ok-only
    centered
    @hidden="handleHide"
    @ok="handleOk"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        訂單紀錄
        <!-- {{ orderId }} -->
      </h4>
    </template>

    <div
      ref="historyContent"
      class="history-modal-content"
    >
      <div
        v-if="!isBusyLoading"
        class="animate__animated animate__fadeIn"
      >
        <my-timeline
          v-if="historyLog.length>0"
          :time-setting="timelineSetting"
          :time-array="historyLog"
        />

        <!-- {{ historyLog }} -->

        <div
          v-if="historyLog.length === 0"
          class="text-center my-5"
        >
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.themeImages.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </div>

      </div>

      <div
        v-else
        class="loading-area"
      >
        <b-img
          :src="$store.state.app.themeImages.loadingImg"
          rounded
          height="60"
          width="60"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import { useOrderHistory } from '../useOrder'
import myTimeline from './Timeline.vue'

export default {
  components: {
    BImg,

    myTimeline,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    modalExplorerId: {
      type: String,
      default: 'history-explorer-modal',
    },
  },
  data() {
    return {
      timelineSetting: {
        type: 'timeline-s',
        background: 'block',
        line: 'icon',
        color: {
          bgColor: '#babfc788',
          primaryColor: '#1685b8',
          primaryTxtColor: '#34ace0',
          lineColor: '#094a68',
          txtColor: '#ffffff',
        },
      },
    }
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$nextTick(() => {
        this.$bvModal.hide(this.modalExplorerId)
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$nextTick(() => {
        this.$bvModal.hide(this.modalExplorerId)
      })
    },

    // (取得)編輯資料
    getData() {
      this.isBusyLoading = true
      this.historyLog = []
      this.getOrderHistory()
      this.$bvModal.show(this.modalExplorerId)
    },
  },
  setup(props) {
    const {
      isBusyLoading,
      historyLog,
      getOrderHistory,
      useAlertToast,
      useHttpCodeAlert,
    } = useOrderHistory(props.orderId)

    return {
      isBusyLoading,
      historyLog,
      getOrderHistory,
      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
.history-modal-content {
  max-height: 70vh;
  overflow-y:auto;
}
.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}
</style>
